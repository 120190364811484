import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import Skeleton from '@material-ui/lab/Skeleton'
import { parseDOM } from 'htmlparser2'

import { getHeadings } from '../../graphql/resolvers/_tableOfContents'

import LayoutContent from '../layout/layoutContent'
import Post from '../../templates/post'
import Page from '../../templates/page'
import CmsHeader from './cmsHeader'

const renderPost = (page, pageData, pageContext) => {
    const _pageData = {
        page: {
            featuredImage: {
                node: {
                    altText: page.featured_image_alt_text,
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: {
                                src: page.featured_image_src
                            }
                        }
                    }
                }
            },
            socialImage: pageData.socialImage,
            title: page.title.rendered,
            content: page.content.rendered,
            acf: page.acf
        },
        site: {
            siteMetadata: {
                socialAlt: pageData.site.siteMetadata.socialAlt,
                siteUrl: pageData.site.siteMetadata.siteUrl
            }
        },
        ...pageData
    }

    return <Post pageContext={pageContext} data={_pageData} isDraft />
}


const renderPage = (page, pageData, pageContext) => {
    const _pageData = {
        page: {
            title: page.title.rendered,
            content: page.content.rendered,
            acf: page.acf
        },
        site: {
            siteMetadata: {
                socialAlt: pageData.site.siteMetadata.socialAlt,
                siteUrl: pageData.site.siteMetadata.siteUrl
            }
        },
        ...pageData
    }

    return <Page pageContext={pageContext} data={_pageData} isDraft />
}

export default ({ pageContext, data, accessToken, contentId, isParentFetching, type = 'post' }) => {
    const [page, setPage] = useState()
    const [tableOfContents, setTableOfContents] = useState({})
    const [isFetching, setFetching] = useState(true)

    useEffect(() => {
        if (!page) return

        const nodes = new parseDOM(page.content.rendered)

        setTableOfContents({
            order: 0,
            title: page.title.rendered,
            path: page.path || '',
            date: page.date,
            headings: Object.values(nodes.reduce((headings, node) => ({
                ...headings,
                ...getHeadings(node)
            }), {})) || []
        })
    }, [page])

    if (!!accessToken && !isParentFetching && isFetching) {
        fetch(`${process.env.GATSBY_WORDPRESS_DOMAIN}/wp-json/wp/v2/${type}s?d=47&status=draft&per_page=100`, {
            method: 'get',
            headers: { Accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` }
        })
            .then(res => res.json())
            .then(pages => {
                var page = pages.find(p => p.id === parseInt(contentId))
                if (page) {
                    setPage(page)
                    setFetching(false)
                } else {
                    navigate('/404')
                }
            })
            .catch(error => {
                setFetching(false)
                window.appInsights.trackException({ error: `Drafts ${type} request failed`, properties: { componentStack: JSON.stringify(error) } })
            })
    }

    return (
        <>
            {isFetching || isParentFetching ? (
                <>
                    <CmsHeader />
                    <LayoutContent {...{ data: data }} setBackgroundImage={true}>
                        <Skeleton width='100%' height='30vw' />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton width='50%' />
                        <Skeleton height='3em' />
                        <Skeleton />
                        <Skeleton width='25%' />
                    </LayoutContent>
                </>
            ) : (
                <>
                    <CmsHeader editLink={`${process.env.GATSBY_WORDPRESS_DOMAIN}/wp-admin/post.php?post=${contentId}&action=edit`} />
                    {type === 'post' && renderPost(page, data, { ...pageContext, isFallback: true, tableOfContents })}
                    {type === 'page' && renderPage(page, data, { ...pageContext, isFallback: true, tableOfContents })}
                </>
            )}
        </>
    )
}
