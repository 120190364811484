import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import Link from '../common/link'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    item: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    }
}))

export default ({ editLink }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <AppBar position='static' color='default'>
                <Toolbar variant='dense'>
                    <Link className={classes.item} to='/drafts' aria-label='Go to drafts page'>
                        Drafts
                    </Link>
                    <Link
                        className={classes.item}
                        href={`${process.env.GATSBY_WORDPRESS_DOMAIN}/wp-admin/`}
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Go to Wordpress'
                    >
                        CMS Home
                    </Link>

                    {!!editLink && (
                        <Link
                            className={classes.item}
                            href={editLink}
                            target='_blank'
                            rel='noopener noreferrer'
                            aria-label='Edit this page in Wordpress'
                        >
                            Edit Page
                        </Link>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    )
}
