const get = require('tcweb-material-components/core/poly/get').default
const map = require('tcweb-material-components/core/poly/map').default
const htmlparser = require('htmlparser2')

const localeUtils = require('../../utilities/locale')

const HEADING_NAMES = ['h1','h2','h3','h4','h5','h6']

const getHeadings = (node) => {
    let headings = {}

    if (node.parent &&
        HEADING_NAMES.includes(node.parent.name) &&
        node.parent.attribs.id &&
        node.type === 'text'
    ) {
        const anchor = node.parent.attribs.id
        const depth = parseInt(node.parent.name[1])

        return {
            [anchor]: {
                anchor: `#${node.parent.attribs.id}`,
                title: node.data,
                depth: isNaN(depth) ? 0 : depth
            }
        }
    }

    if (node.children) {
        for (let child of node.children) {
            headings = {...headings, ...getHeadings(child)}
        }
    }

    if (node.next) {
        headings = {...headings, ...getHeadings(node.next)}
    }

    return headings
}

const resolver = (type) => async (source, args, context, info) => {
    const isParentPage = !source.wpParent
    const isTranslation = source.locale.locale !== localeUtils.defaultWPMLLocale
    const translatedIDs = map(i => !!i && i.id, source.translated)

    // Try to find english node, if doesn't exist fallback to source
    const node = await context.nodeModel.runQuery({
        query: {
            filter: { id: { in: translatedIDs }, locale: { locale: { eq: localeUtils.defaultWPMLLocale } } },
        },
        firstOnly: true,
        type: type,
    }) || source

    if(!get(source, 'toc.hasTableOfContents') && !get(node, 'toc.hasTableOfContents')) return null
    const nodes = new htmlparser.parseDOM(isTranslation ? source.content : node.content)

    return {
        order: isParentPage ? -1 : get(source, 'menuOrder', 0),
        title: isTranslation ? source.title : node.title,
        path: node.uri,
        date:  node.date,
        headings: Object.values(nodes.reduce((headings, node) => ({
            ...headings,
            ...getHeadings(node)
        }), {})) || []
    }
}

module.exports = { resolver: resolver, getHeadings: getHeadings }